@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
  @apply h-full;
}

.section-container {
  @apply px-4 sm:px-6 lg:px-8;
}

.text-link {
  @apply text-blue-700 hover:text-blue-900;
}

fieldset + fieldset {
  @apply mt-8 pt-8 border-t border-gray-200 border-solid sm:mt-10 sm:pt-10;
}

.text-label {
  @apply text-sm font-medium text-gray-700;
}

.table-preview td {
  @apply p-2;
}

.text-muted {
  @apply text-gray-500;
}

.text-lead {
  @apply text-base text-gray-600;
}

body > .sortable-item {
  cursor: grabbing;
  pointer-events: auto !important;
}
